<template>
    <el-dialog v-model="dialogFormVisible" :title="title" @close="closeDialog">
        <el-form :model="addDeviceTypeForm" ref="addDeviceTypeRef" :rules="addDeviceTyperules">
                        <el-form-item label="设备名称" :label-width="formLabelWidth"
                                      prop="devicetype">
                            <el-input
                                    v-model="addDeviceTypeForm.devicetype"
                                    autocomplete="off"
                                    ref="devicetype"
                                    placeholder="请填写设备名称"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="排序" :label-width="formLabelWidth"
                                      prop="sort">
                            <el-input
                                    v-model="addDeviceTypeForm.sort"
                                    autocomplete="off"
                                    ref="name"
                                    placeholder="请填写排序"
                            ></el-input>
                        </el-form-item>
        </el-form>
        <template #footer>
      <span class="dialog-footer">
        <el-button @click="cancel()">取消</el-button>
        <el-button type="primary" @click="save()">保存</el-button>
      </span>
        </template>
    </el-dialog>
</template>

<script>
    import {ref} from "vue";
    import {httpService} from "@/utils/httpService";
    import {ElMessage, ElForm} from "element-plus";
    import {vxRule} from "@/utils/validator";

    export default {
        name: "AddDeviceType",
        components: {ElForm, ElMessage},
       
        data() {
            return {
                dialogFormVisible: ref(false),
                formLabelWidth: "120px",
                title: "",
                personnelType: "",
                addDeviceTypeForm: {
                    id:"",
                    devicetype: "",
                    sort: ""
                },
                // 校验规则
                addDeviceTyperules: {
                    name: vxRule(true, null, "blur")
                },
            };
        },
        methods: {
            closeDialog() {
                this.clear();
            },
            cancel() {
                this.dialogFormVisible = false;
                this.clear();
            },
            getDeviceTypeById(id) {
                httpService("/api/deviceType/queryById", {id: id}, "get").then(
                    (data) => {
                        this.addDeviceTypeForm = data;
                    }
                );
            },
            clear() {
                this.dialogFormVisible = false;
                //清空form
                    this.addDeviceTypeForm.id = "";
                    this.addDeviceTypeForm.devicetype = "";
                    this.addDeviceTypeForm.sort = "";
            },
            save() {
                this.$refs.addDeviceTypeRef.validate((valid) => {
                    if (valid) {
                        let params = new FormData(); //创建一个form对象,必须是form对象否则后端接受不到数据
                        params.append("devicetype", this.addDeviceTypeForm.devicetype);
                        params.append("sort", this.addDeviceTypeForm.sort);
                        if (this.addDeviceTypeForm.id) {
                            params.append("id", this.addDeviceTypeForm.id);
                            httpService("/api/deviceType/edit", params, "post").then(
                                (data) => {
                                    if (data.code == 200) {
                                        ElMessage.success("修改成功");
                                        this.clear();
                                        //刷新父组件的数据
                                        this.$parent.getDeviceTypeList();
                                    } else {
                                        ElMessage.error("修改失败");
                                    }
                                }
                            );
                        } else {
                            httpService("/api/deviceType/add", params, "post").then(
                                (data) => {
                                    if (data.code == 200) {
                                        ElMessage.success("添加成功");
                                        this.clear();
                                        //刷新父组件的数据
                                        this.$parent.getDeviceTypeList();
                                    } else {
                                        ElMessage.error("添加失败");
                                    }
                                }
                            );
                        }
                    } else {
                        return false;
                    }
                });
            },
        },
    };
</script>
<style>
</style>