<template>
    <addEditDeviceTypeView ref="addEditDeviceTypeView"/>
    <addFileView ref="addFileView" />
    <el-main>
        <div class="teacher">
            <div class="app-table">
                <div>
                    <div class="app-search">
                        <el-form :inline="true" class="demo-form-inline" v-model="form">
                                        <el-form-item label="设备名称">
                                            <el-input
                                                    placeholder="请输入设备名称"
                                                    v-model="form.deviceType"
                                            ></el-input>
                                        </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="onSubmit"   @keyup.enter="searchEnter">搜索</el-button>
                                <el-button type="success" @click="add">添加</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                    <el-table
                            size="small"
                            :data="list"
                            style="width: 100%"
                            border
                            :row-class-name="tableRowClassName"
                    >
                                <el-table-column prop="id" label="id"/>
                                <el-table-column prop="devicetype" label="设备名称"/>
                                <el-table-column prop="sort" label="排序"/>
                        <el-table-column label="操作" width="140px">
                            <template style="display: block" #default="scope">
                                <el-button
                                        size="small"
                                        type="success"
                                        @click="editDeviceType(scope.row)"
                                >编辑
                                </el-button
                                >
                                <el-button
                                        size="small"
                                        type="danger"
                                        @click="deleteDeviceType(scope.row.id)"
                                >删除
                                </el-button
                                >
                            </template>
                        </el-table-column>
                        <template v-slot:empty>
                            <el-empty description="暂无数据"></el-empty>
                        </template>
                    </el-table>
                    <el-config-provider :locale="locale">
                        <el-pagination
                                :total="total"
                                :page-size="limit"
                                :page-sizes="[5, 10, 20, 30, 40, 50, 100]"
                                layout="total, sizes, prev, pager, next, jumper"
                                @size-change="changePageSize"
                                @current-change="changeCurrentPage"
                        >
                        </el-pagination>
                    </el-config-provider>
                </div>
            </div>
        </div>
    </el-main>
</template>
<script>
    import addEditDeviceTypeView from "@/components/addEditDeviceType.vue";
    import addFileView from "@/components/addExcelFile.vue";
    import {ElConfigProvider, ElMessageBox, ElMessage} from "element-plus";
    import zhCn from "element-plus/lib/locale/lang/zh-cn";
    import {httpService} from "@/utils/httpService";
    import {Picture as IconPicture,Upload } from "@element-plus/icons-vue";

    export default {
        name: "DeviceTypeLIstView",
        data() {
            return {
                type: 0,
                title: "",
                page: 0,
                pageSize: 10,
                total: 0,
                limit: 10,
                list: [],
                form: {
                    deviceType: ""
                },
            };
        },
        components: {
            addEditDeviceTypeView,
            addFileView,
            IconPicture,
            ElConfigProvider,
            ElMessageBox,
            ElMessage,Upload
        },
        setup() {
            return {
                locale: zhCn,
            };
        },
        mounted() {
            this.getDeviceTypeList();
        },
        watch: {
            $route() {
                this.getDeviceTypeList();
            },
        },
        methods: {
            searchEnter: function (e) {
                // 使用 which 和 keyCode 属性来解决兼容问题
                var keyCode = window.event ? e.keyCode : e.which;
                var val = e.target.value;
                if (keyCode == 13 && val) {
                    this.getDeviceTypeList();
                }
            },
            add() {
                this.$refs.addEditDeviceTypeView.title = "添加设备类型"
                this.$refs.addEditDeviceTypeView.personnelType = this.type
                this.$refs.addEditDeviceTypeView.dialogFormVisible = true;
            },
            editDeviceType(obj) {
                this.$refs.addEditDeviceTypeView.title = "修改设备类型"
                this.$refs.addEditDeviceTypeView.getDeviceTypeById(obj.id);
                this.$refs.addEditDeviceTypeView.dialogFormVisible = true;
            },
            deleteDeviceType(id) {
                ElMessageBox.confirm("确定要删除本条记录吗？", "提醒", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        httpService("/api/deviceType/deleteById", "id=" + id, "post").then(
                            (data) => {
                                if (data.code == 200) {
                                    ElMessage.success("删除成功");
                                    this.getDeviceTypeList();
                                } else {
                                    ElMessage.error("删除失败，请重试！");
                                }
                            }
                        );
                    })
                    .catch(() => {
                    });
            },
            reloadData(){
                this.page=1;
                this.getDeviceTypeList()
            },
        
        
            onSubmit() {
                this.getDeviceTypeList();
            },
            // 每页记录数改变，size：回调参数，表示当前选中的“每页条数”
            changePageSize(size) {
                this.pageSize = size;
                this.limit = size;
                this.getDeviceTypeList();
            },

            // 改变页码，page：回调参数，表示当前选中的“页码”
            changeCurrentPage(page) {
                this.page = page;
                this.getDeviceTypeList();
            },
            tableRowClassName({row, rowIndex}) {
                return "";
            },
            getDeviceTypeList() {
                httpService(
                    "/api/deviceType/list/" + this.page + "/" + this.pageSize,
                    {
                        "devicetype": this.form.deviceType,
                    },
                    "get"
                ).then((data) => {
                    this.list = data.list;
                    this.total = data.total;
                    this.pageCount = data.pages;
                });
            },
        },
    };
</script>
<style scoped>
    .el-main {
        padding: 10px;
    }

    .teacher-content {
        width: 100%;
    }

    .app-table {
        background: white;
        padding: 10px;
    }

    .app-search {
        padding-top: 10px;
        margin-top: 10px;
    }

    .app-separator {
        padding-top: 10px;
        padding-bottom: 20px;
        padding-left: 10px;
    }

    .app-body {
        background: white;
        padding: 20px 20px 10px 20px;
        margin-bottom: 10px;
    }

    .el-table .warning-row {
        --el-table-tr-bg-color: var(--el-color-warning-lighter);
    }

    .el-table .success-row {
        --el-table-tr-bg-color: var(--el-color-success-lighter);
    }

    .el-pagination {
        text-align: center;
        margin-top: 10px;
    }
</style>

